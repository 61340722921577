<template>
  <div class="right">
    <div class="title">
      <span>退换货详情</span>
    </div>
    <div class="content">
      <div class="re-wares">
        <div class="re-wares-img">
          <img />
        </div>
        <div class="re-wares-text">
          <div class="re-wares-no">
            <span>售后服务单号：</span>
            <span>4304803320</span>
          </div>
          <div class="re-wares-name">
            <span>甲烷气体/氮气/空气 2升 4升 8升</span>
          </div>
        </div>
      </div>

      <div class="dititle">
        <span>售后信息</span>
      </div>

      <div class="progress active">
        <div class="progress-title">
          <span>请耐心等待，正在处理中</span>
        </div>
        <div class="progress-content">
          <svg class="progress-svg progress-2" width="653" height="233">
            <defs>
              <linearGradient id="progress_g_s">
                <stop stop-color="#73b168" offset="0%" stop-opacity="1" />
                <stop stop-color="#73b168" offset="80%" stop-opacity="1" />
                <stop stop-color="#d7d7d7" offset="100%" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="progress_g_c">
                <stop stop-color="#fd902b" offset="0%" stop-opacity="0" />
                <stop stop-color="#73b168" offset="20%" stop-opacity="1" />
                <stop stop-color="#73b168" offset="50%" stop-opacity="1" />
                <stop stop-color="#73b168" offset="80%" stop-opacity="1" />
                <stop stop-color="#d7d7d7" offset="100%" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="progress_g_e">
                <stop stop-color="#fd902b" offset="0%" stop-opacity="0" />
                <stop stop-color="#73b168" offset="20%" stop-opacity="1" />
                <stop stop-color="#73b168" offset="100%" stop-opacity="1" />
              </linearGradient>
            </defs>

            <rect x="126" y="90" width="400" height="5" fill="#d7d7d7" />

            <image x="124" y="75" width="36" height="36" xlink:href="../../../assets/order/re-progress-y.png"></image>
            <image x="224" y="75" width="36" height="36" xlink:href="../../../assets/order/re-progress-y.png"></image>
            <image x="324" y="75" width="36" height="36" xlink:href="../../../assets/order/re-progress-y.png"></image>
            <image x="424" y="75" width="36" height="36" xlink:href="../../../assets/order/re-progress-y.png"></image>
            <image x="524" y="75" width="36" height="36" xlink:href="../../../assets/order/re-progress-y.png"></image>

            <rect class="rect-o-1" x="126" y="90" width="124" height="5" fill="#ff8f29" />
            <rect class="rect-o-2" x="126" y="90" width="224" height="5" fill="#ff8f29" />
            <rect class="rect-o-3" x="126" y="90" width="324" height="5" fill="#ff8f29" />
            <rect class="rect-o-4" x="126" y="90" width="400" height="5" fill="#ff8f29" />

            <rect class="rect-g-1" x="126" y="90" width="70" height="5" fill="url(#progress_g_s)" />
            <rect class="rect-g-2" x="189" y="90" width="110" height="5" fill="url(#progress_g_c)" />
            <rect class="rect-g-3" x="289" y="90" width="110" height="5" fill="url(#progress_g_c)" />
            <rect class="rect-g-4" x="390" y="90" width="110" height="5" fill="url(#progress_g_c)" />
            <rect class="rect-g-5" x="490" y="90" width="60" height="5" fill="url(#progress_g_e)" />

            <image class="progress-g-1" x="124" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-g.png"></image>
            <image class="progress-g-2" x="224" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-g.png"></image>
            <image class="progress-g-3" x="324" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-g.png"></image>
            <image class="progress-g-4" x="424" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-g.png"></image>
            <image class="progress-g-5" x="524" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-g.png"></image>

            <image class="progress-o-1" x="124" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-o.png"></image>
            <image class="progress-o-2" x="224" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-o.png"></image>
            <image class="progress-o-3" x="324" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-o.png"></image>
            <image class="progress-o-4" x="424" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-o.png"></image>
            <image class="progress-o-5" x="524" y="75" width="36" height="36"
              xlink:href="../../../assets/order/re-progress-o.png"></image>

            <text class="text-1 text-title" x="118" y="130">提交申请</text>
            <text class="text-1 text-time" x="109" y="150">2020-07-14</text>
            <text class="text-1 text-time" x="118" y="170">16:48:10</text>

            <text class="text-2 text-title" x="220" y="130">卖家审核</text>
            <text class="text-2 text-time" x="214" y="150">2020-07-14</text>
            <text class="text-2 text-time" x="220" y="170">16:48:10</text>

            <text class="text-3 text-title" x="320" y="130">卖家收货</text>
            <text class="text-3 text-time" x="314" y="150">2020-07-14</text>
            <text class="text-3 text-time" x="320" y="170">16:48:10</text>

            <text class="text-4 text-title" x="424" y="130">退款中</text>
            <text class="text-4 text-time" x="414" y="150">2020-07-14</text>
            <text class="text-4 text-time" x="420" y="170">16:48:10</text>

            <text class="text-5 text-title" x="528" y="130">完成</text>
            <text class="text-5 text-time" x="514" y="150">2020-07-14</text>
            <text class="text-5 text-time" x="520" y="170">16:48:10</text>
          </svg>

          <div class="tips">
            <span>
              重要提示：平台以及经销商都不会以
              <span class="major">任何理由</span>
              ，要求您点击
              <span class="major">任何网址链接</span>
              进行退款操作。
              <span class="major">《防诈骗声明》</span>
            </span>
          </div>
        </div>

      </div>

      <div class="dititle">
        <span>服务单信息</span>
      </div>

      <div class="re-info">
        <div class="re-info-item">
          <div class="re-info-item-title">
            <span>商品返回方式</span>
          </div>
          <div class="re-info-item-content">
            <span>客户期望xxxxx</span>
          </div>
        </div>

        <div class="re-info-item">
          <div class="re-info-item-title">
            <span>商品返回方式</span>
          </div>
          <div class="re-info-item-content">
            <span>客户期望xxxxx</span>
          </div>
        </div>

        <div class="re-info-item">
          <div class="re-info-item-title">
            <span>商品返回方式</span>
          </div>
          <div class="re-info-item-content">
            <span>客户期望xxxxx</span>
          </div>
        </div>

        <div class="re-info-item">
          <div class="re-info-item-title">
            <span>商品返回方式</span>
          </div>
          <div class="re-info-item-content">
            <span>客户期望xxxxx</span>
          </div>
        </div>

        <div class="re-info-item">
          <div class="re-info-item-title">
            <span>商品返回方式</span>
          </div>
          <div class="re-info-item-content">
            <span>客户期望xxxxx</span>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss" scoped>
  .right {
    width: 976px;
    padding-top: 34px;
    padding-left: 27px;
    background: #fff;
    padding-right: 30px;

    .title {
      font-size: 26px;
      font-weight: 600;
      color: #616161;
    }

    .content {
      padding: 28px;

      .re-wares {
        height: 120px;
        width: calc(100% - 40px);
        background: #eff3ff;
        border: 1px solid #d7defb;
        display: inline-flex;
        padding: 0px 20px;

        &-img {
          display: inline-block;
          width: 107px;
          height: 107px;
          margin: auto 0;

          >img {
            width: 100%;
            height: 100%;
          }
        }

        &-text {
          display: inline-block;
          margin-left: 10px;
          padding: 25px 10px;
        }
      }

      .dititle {
        font-size: 18px;
        font-weight: 500;
        margin-top: 50px;
        margin-bottom: 20px;
      }

      .progress {
        border: 1px solid #efefef;
        border-top: 5px solid #bde2b7;

        &-title {
          width: 100%;
          height: 46px;
          background: #f1f8f0;
          text-align: center;
          line-height: 46px;
          color: #639f59;

          >span {
            position: relative;

            &:before {
              position: absolute;
              content: "";
              display: inline-block;
              height: 8px;
              width: 37px;
              background: transparent url("../../../assets/order/re-progress.png");
              background-size: 100% 100%;
              left: -46px;
              top: 8px;
            }
          }
        }

        &-content {
          width: 100;

          .progress-svg {
            display: inline-block;
            margin: 0 auto;

            @for $i from 1 through 5 {
              &.progress-#{$i} {
                @if $i>=2 {
                  @for $o from 1 through $i {

                    .rect-o-#{$o - 1},
                    .progress-o-#{$o - 1} {
                      visibility: visible;
                    }

                    .text-#{$o - 1} {
                      fill: #fd902b;

                      &.text-time {
                        visibility: visible;
                      }
                    }
                  }
                }

                .rect-g-#{$i},
                .progress-g-#{$i} {
                  visibility: visible;
                }

                .text-#{$i} {
                  &.text-time {
                    visibility: visible;
                  }

                  fill: #73b168;
                }
              }
            }

            rect[class*="rect-o"],
            rect[class*="rect-g"],
            image[class*="rect-o"],
            image[class*="progress-o"],
            image[class*="progress-g"],
            text[class*="text-time"] {
              visibility: hidden;
            }

            text[class*="text-"],
              {
              fill: #d7d7d7;
            }

            .text-title {
              font-weight: 550;
              font-size: 18px;
            }

          }

          .tips {
            height: 60px;
            line-height: 60px;
            padding: 0px 30px;
            font-size: 18px;
            font-weight: 500;
            color: #f14a41;

            .major {
              font-weight: 600;
            }
          }
        }

        &.active {
          border-top: 5px solid #fe912c;

          .progress-title {
            color: #fe912c;

            >span {
              &:before {
                background-image: url("../../../assets/order/re-progress-active.png");
              }
            }
          }
        }
      }

      .re-info {
        width: 100%;
        border: 1px solid #efefef;

        &-item {
          width: 100%;
          border-bottom: 1px solid #efefef;
          display: flex;
          font-size: 18px;
          font-weight: 550;
          &-title {
            width: 121px;
            padding: 15px 30px;
            border-right: 1px solid #efefef;
          }
          &-content{
            flex: 100;
            padding: 15px 30px;
          }

          &:last-of-type {
            border-bottom: 0px;
          }
        }
      }

    }


  }
</style>